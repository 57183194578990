import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonPrimaryCSS = css`
  background-color: ${(props) => props.theme.color.secondary};
  display: inline-block;
  transition: all 0.3s ease;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    padding: 17px 45px;
    width: 100%;
    height: 100%;
    display: block;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    text-decoration: none !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const StyledButton = styled.span`
  ${ButtonPrimaryCSS};
`

interface ButtonPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimary
