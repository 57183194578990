import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Header from 'components/layout/Header/Header'
import Footer from 'components/layout/Footer'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'styles/all.css'

export interface LayoutContextProps {
  locale: string
  translations: Array<{
    href: string
    locale: string
  }>
  isScrollable: boolean
  setIsScrollable: Function
  filter: any
  setFilter: Function
  currentPageType: any
  setCurrentPageType: Function
}

export const LayoutContext = React.createContext({
  locale: 'nl_NL',
  translations: [],
  isScrollable: true,
  setIsScrollable: (bool: any) => bool,
  filter: [],
  setFilter: (bool: any) => bool,
  currentPageType: '',
  setCurrentPageType: (bool: any) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>`
  padding-top: 120px;

  @media screen and (max-width: 991px) {
    padding-top: 100px;
  }
`

interface LayoutProps {
  children: any
  locale?: string
  translations?: any
  id?: number
}

const Layout: React.FC<LayoutProps> = ({
  children,
  locale = 'nl_NL',
  translations,
  id,
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const [filter, setFilter] = React.useState([])
  const [currentPageType, setCurrentPageType] = React.useState<string>('')

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <LayoutContext.Provider
        value={{
          setFilter,
          filter,
          isScrollable,
          setIsScrollable,
          locale,
          translations,
          currentPageType,
          setCurrentPageType,
        }}
      >
        <Header id={id} isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <Content isSticky={isSticky}>{children}</Content>
        <Footer />
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
