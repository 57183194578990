/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Images
import Chevron from 'img/chevron.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const Item = styled(motion.li)`
  margin-left: 40px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  & div > a {
    color: ${(props) => props.theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-transform: uppercase;
    font-size: 14px;
    transition: all 0.3s ease;

    &[aria-current] {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    &:hover {
      /* font-weight: ${({ theme }) => theme.font.weight.bold}; */
      color: ${({ theme }) => theme.color.dark};
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;
    margin: 0;

    & div > a {
      font-size: 50px;
      line-height: 70px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};

      &[aria-current] {
        color: ${({ theme }) => theme.color.primary};
        /* opacity: 0.5; */
      }
    }
  }

  @media screen and (max-width: 350px) {
    & div > a {
      font-size: 40px;
    }
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const SvgWrapper = styled.div<{ open: boolean }>`
  margin-left: 15px;

  & > svg {
    transition: all 0.3s ease;

    ${({ open }) =>
      open
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }
`

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => (
        <Item
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          onTap={open === index ? () => setOpen(-1) : () => setOpen(index)}
          key={index}
        >
          <div>
            <NavLink to={l?.link?.url || '/'}>{l?.link?.title}</NavLink>
            {l?.submenu && (
              <SvgWrapper open={open === index}>
                <Chevron />
              </SvgWrapper>
            )}
          </div>
          {l?.submenu && (
            <AnimatePresence>
              {open === index && <Submenu fields={l.submenu} />}
            </AnimatePresence>
          )}
        </Item>
      ))}
    </>
  )
}

export default Menu
