import React from 'react'
import styled from 'styled-components'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'

// Image
import ChevronDown from 'img/chevron-down.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import { motion } from 'framer-motion'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Title = styled(ParseContent)`
  & h2 {
    font-size: 40px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    opacity: 0.3;
  }
`

const HighlightedBlog: React.FC<BlogProps> = ({ fields }) => {
  const { allWpPost } =
    useStaticQuery<GatsbyTypes.highlightedpostQueryQuery>(graphql`
      query highlightedpostQuery {
        allWpPost(sort: { fields: date, order: DESC }) {
          edges {
            node {
              ...generalPostFragment
            }
          }
        }
      }
    `)

  const posts: unknown = allWpPost.edges

  const showIds: Array<number> = []

  fields.highlighted?.forEach((h) => {
    showIds.push(h?.databaseId || 0)
  })

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={[]}
        showIds={showIds}
        id="highlightedblog"
        limit={Infinity}
      >
        <div className="py-lg-5 py-3">
          <div className="container pb-;g-3">
            <div className="row justify-content-center">
              <div className="col-lg-10 p-2">
                <Title content={fields.title || ''} />
              </div>
            </div>
          </div>
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <div key={post.node.id} className="col-12 mt-2">
                  <BlogGridPost node={node} blogFields={fields} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const StyledBlogGridPost = styled(motion.div)`
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
`

const StyledPlaatjie = styled(Plaatjie)`
  position: relative;
  height: 250px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const Content = styled(ParseContent)`
  & p {
    font-size: 16px;
    line-height: 24px;
    font-weight: ${({ theme }) => theme.font.weight.light};

    & b,
    strong {
      font-size: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: 16px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  & svg {
    width: 10px;
    height: 18px;
    margin-left: 5px;
    transform: rotate(270deg);

    & g {
      opacity: 1;
    }

    & rect,
    path {
      fill: ${({ theme }) => theme.color.dark};
    }
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => (
  <StyledBlogGridPost onClick={() => navigate(node.uri || '/')}>
    <div className="row">
      <div className="col-md-4 p-2 pe-lg-3">
        <StyledPlaatjie image={node.recap?.image} alt="" />
      </div>
      <div className="col-md-8 p-2 d-flex align-items-center">
        <div>
          <Content content={node.recap?.excerpt || ''} />
          <div className="pt-lg-4 pt-3">
            <StyledLink to={node.uri || '/'}>
              {blogFields.loadmoretext}
              <ChevronDown />
            </StyledLink>
          </div>
        </div>
      </div>
    </div>
  </StyledBlogGridPost>
)

export default HighlightedBlog
