import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Images
import Facebook from 'img/facebook.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import Plaatjie from '@ubo/plaatjie'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;

    @media screen and (max-width: 991px) {
      margin-top: 1.3rem;
      margin-bottom: 1.3rem;
    }
  }

  & .form-duuf-group-submit {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.color.dark};
    border-radius: 0;
    min-height: 38px;
    font-size: 14px;
    padding: 0;
    font-weight: ${({ theme }) => theme.font.weight.regular};

    &::placeholder {
      color: ${({ theme }) => theme.color.dark};
      font-size: 14px;
    }
  }

  & .form-duuf-textarea {
    height: 38px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Content = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 40px;
    padding-bottom: 20px;
    opacity: 0.3;
  }

  & p {
    font-size: 14px;
    line-height: 24px;
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  & a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  @media screen and (max-width: 991px) {
    & h2 {
      padding-bottom: 8px;
    }
  }

  @media screen and (max-width: 576px) {
    & h2 {
      font-size: 30px;
    }
  }
`

const Social = styled.div`
  & svg {
    & g {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    & svg {
      & g {
        opacity: 1;
      }

      & path {
        fill: ${({ theme }) => theme.color.dark};
      }
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  width: 100%;
  height: calc(100vh - 120px);
  max-height: 768.57px;

  @media (max-width: 1199px) {
    max-height: 553.57px;
  }

  @media (max-width: 991px) {
    max-height: 409.57px;
  }

  @media screen and (max-width: 991px) {
    height: calc(100vh - 100px);
  }

  @media (max-width: 576px) {
    max-height: 296.75px;
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section>
    <div className="container pb-lg-5 pb-3">
      <div className="row">
        <div className="col-lg-12">
          <StyledPlaatjie loading="eager" image={fields.image} alt="" />
        </div>
        <div className="col-lg-12 pt-xl-3 pt-4">
          <div className="h-100 pe-lg-5 d-flex align-items-end">
            <div className="w-100 pe-lg-4">
              <div className="row flex-wrap-reverse pt-3 pb-lg-5 pb-3">
                <div className="col-lg-5 pt-lg-0 pt-4">
                  <Content content={fields.description || ''} />
                  <div className="d-flex pt-4">
                    <Social className="me-3">
                      <a target="__blank" href={fields.facebook?.url}>
                        <Facebook />
                      </a>
                    </Social>
                    <Social>
                      <a target="__blank" href={fields.linkedin?.url}>
                        <Linkedin />
                      </a>
                    </Social>
                  </div>
                </div>
                <div className="col-lg-7">
                  <Content content={fields.title || ''} />
                  <StyledFormDuuf id={1} generate={false}>
                    <div className="row">
                      <div className="col-lg-6">
                        <FormDuufGroup showIds={[4, 1]} />
                      </div>
                      <div className="col-lg-6">
                        <FormDuufGroup showIds={[6, 2]} />
                      </div>
                      <div className="col-lg-12 d-flex justify-content-start">
                        <FormDuufSubmit />
                      </div>
                    </div>
                  </StyledFormDuuf>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default FormContact
