import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'

// Images
import Logo from 'img/bbLogoWhite.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'
import useLayout from 'hooks/useLayout'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 11;

  & h2 {
    font-size: 16px;
  }

  & ul {
    list-style: none;
    padding-left: 0;

    & li {
      font-size: 14px;
      line-height: 26px;
      font-weight: ${({ theme }) => theme.font.weight.light};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Brand = styled(NavLink)`
  color: ${({ theme }) => theme.color.light};
  font-size: 22px;
  line-height: 24px;

  & > svg {
    height: 30px;
    width: 30px;
  }

  .brand-small {
    font-size: 14px;
    line-height: 14px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const Content = styled(ParseContent)`
  & p {
    font-size: 14px;
    line-height: 26px;
  }

  & a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 575px) {
    & p {
      text-align: left !important;
    }
  }
`

const AlgemeneVoorwaarden = styled.div`
  & a {
    color: ${({ theme }) => theme.color.light};
    font-size: 14px;
    text-align: right;

    &:hover {
      color: ${({ theme }) => theme.color.light};
      text-decoration: underline;
    }
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            link {
              title
              url
            }
          }
          information
          algemenevoorwaarden {
            localFile {
              url
            }
          }
        }
      }
    }
  `)

  const layout = useLayout()

  const handleFilterAlt = (f: string) => {
    layout.setFilter([f])
    navigate('/')
  }

  return (
    <StyledFooter id="footer">
      <div className="container py-lg-5 py-4">
        <div className="row">
          <div className="col-sm-6">
            <Brand to="/">
              <div className="d-flex">
                <Logo />
                <div className="ps-3 d-flex flex-column justify-content-between">
                  <div>
                    <div>BLOKHUIS</div>
                    <div>BRAAKMAN</div>
                  </div>
                  <div className="brand-small">architectenbureau bna</div>
                </div>
              </div>
            </Brand>
            <div className="pt-5">
              <ul>
                {fields?.footer?.menuFooter?.map(
                  ({ link: { title } }: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                      <div
                        tabIndex={-1}
                        role="button"
                        onKeyDown={() => handleFilterAlt(title)}
                        onClick={() => handleFilterAlt(title)}
                      >
                        {title}
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="col-sm-6 pt-sm-0 pt-3">
            <div className="d-flex justify-content-sm-end">
              <div>
                <h2 className="text-sm-end">CONTACT</h2>
                <div className="pt-sm-4 pt-2">
                  <Content content={fields?.footer?.information || ''} />
                  <AlgemeneVoorwaarden className="pt-3 d-flex justify-content-end">
                    {console.log(fields?.footer?.algemenevoorwaarden)}
                    <a
                      target="_blank"
                      href={fields?.footer?.algemenevoorwaarden?.localFile?.url}
                      rel="norefferer noopener"
                    >
                      Algemene voorwaarden
                    </a>
                  </AlgemeneVoorwaarden>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
