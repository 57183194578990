import React from 'react'
import styled from 'styled-components'
import { graphql, navigate, useStaticQuery } from 'gatsby'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import { motion, AnimatePresence } from 'framer-motion'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const { allWpPost } = useStaticQuery<GatsbyTypes.postQueryQuery>(graphql`
    query postQuery {
      allWpPost(sort: { fields: date, order: ASC }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={[]}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogFilters />
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container d-flex">
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="mr-3"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </div>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const CustomCol = styled.div`
  @media screen and (min-width: 992px) {
    flex: 0 0 auto;
    width: 20%;
  }
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <CustomCol key={post.node.id} className="mt-5">
              <BlogGridPost node={node} blogFields={fields} />
            </CustomCol>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const StyledBlogGridPost = styled(motion.div)`
  position: relative;
  padding-top: 100%;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const Content = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.light};

  & > div {
    padding: 10px 20px 10px 20px;
  }

  & p {
    font-size: 12px;
    line-height: 16px;
    font-weight: ${({ theme }) => theme.font.weight.light};

    & b,
    strong {
      font-size: 14px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [hover, setHover] = React.useState(false)

  return (
    <StyledBlogGridPost
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      onClick={() => navigate(node.uri || '/')}
    >
      <StyledPlaatjie image={node.recap?.image} alt="" />
      <AnimatePresence>
        {hover && (
          <Content
            initial={{ opacity: 0.3, height: 0 }}
            animate={{ opacity: 1, height: 'fit-content' }}
            exit={{ opacity: 0.3, height: 0 }}
          >
            <div>
              <ParseContent content={node.recap?.excerpt || ''} />
            </div>
          </Content>
        )}
      </AnimatePresence>
    </StyledBlogGridPost>
  )
}

export default Blog
