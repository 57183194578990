import React from 'react'
import styled from 'styled-components'
import { graphql, navigate, useStaticQuery } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import { motion, AnimatePresence } from 'framer-motion'
import useLayout from 'hooks/useLayout'
import { useMedia } from 'react-use'
import { concat } from 'lodash'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Project: React.FC<BlogProps> = ({ fields }) => {
  const { allWpProject } =
    useStaticQuery<GatsbyTypes.projectQueryQuery>(graphql`
      query projectQuery {
        allWpProject(sort: { fields: menuOrder, order: ASC }) {
          edges {
            node {
              ...generalProjectFragment
            }
          }
        }
      }
    `)

  const [altPosts, setAltPosts] = React.useState([])

  const posts: unknown = concat(
    allWpProject.edges.filter((e) => e.node.menuOrder === null),
    allWpProject.edges.filter((e) => e.node.menuOrder !== null)
  )
  const layout = useLayout()

  React.useEffect(() => {
    if (layout.filter.length !== 0) {
      setAltPosts(
        // @ts-ignore
        posts.filter(
          (p: any) =>
            p.node.projectType.nodes.filter((n: any) =>
              // @ts-ignore
              layout.filter.includes(n.slug)
            ).length > 0
        )
      )
    } else {
      // @ts-ignore
      setAltPosts(posts)
    }
  }, [layout.filter])

  return (
    <section>
      <BlogBink
        posts={altPosts as BlogBinkPosts}
        categories={[]}
        id="blog"
        limit={Infinity}
      >
        <div className="pb-lg-4 pb-2">
          <ProjectFilters />
          <ProjectGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const StyledProjectFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface ProjectFiltersProps {}

interface ProjectFilterProps {
  node: {
    id: string
    name: string
  }
}

const ProjectFilters: React.FC<ProjectFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container d-flex">
      {blogBink.categories.map((category: ProjectFilterProps) => (
        <StyledProjectFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="mr-3"
        >
          {category.node.name}
        </StyledProjectFilter>
      ))}
    </div>
  )
}

interface ProjectGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const CustomCol = styled.div`
  flex: 0 1 calc(20% - 15px);
  padding: 0 !important;

  @media screen and (max-width: 1199px) {
    flex: 0 1 calc(20% - 8px);
  }

  @media screen and (max-width: 575px) {
    flex: 0 1 calc(25% - 8px);
  }
`

const CustomRow = styled.div`
  gap: 18px;
  display: flex;
  flex-flow: row wrap;
  position: relative;

  @media screen and (max-width: 1199px) {
    gap: 10px;
  }

  @media screen and (max-width: 350px) {
    /* margin-right: -7px;
    margin-left: -7px; */
  }
`

const ProjectGrid: React.FC<ProjectGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <CustomRow>
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <CustomCol key={post.node.id}>
              <ProjectGridPost node={node} blogFields={fields} />
            </CustomCol>
          )
        })}
      </CustomRow>
    </div>
  )
}

interface ProjectGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpProject
}

const StyledProjectGridPost = styled(motion.div)`
  position: relative;
  padding-top: 100%;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const Content = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.light};
  display: flex;
  align-items: flex-end;

  & > div {
    padding: 10px 20px 10px 20px;
  }

  & p {
    font-size: 12px;
    line-height: 16px;
    font-weight: ${({ theme }) => theme.font.weight.light};

    & b,
    strong {
      font-size: 14px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }

  @media (max-width: 1199px) {
    & > div {
      padding: 5px;
    }
  }
`

const ProjectGridPost: React.FC<ProjectGridPostProps> = ({ node }) => {
  const [hover, setHover] = React.useState(false)
  const isMobile = useMedia(`(max-width: 991px)`)

  return (
    <StyledProjectGridPost
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      onTap={() => setHover(!hover)}
      onClick={() => !isMobile && navigate(node.uri || '/')}
    >
      <StyledPlaatjie
        loading="eager"
        image={node.previewproject?.previewimage}
        alt=""
      />
      <AnimatePresence>
        {hover && (
          <Content
            initial={{ opacity: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              tabIndex={-1}
              role="button"
              onKeyDown={() => isMobile && navigate(node.uri || '/')}
              onClick={() => isMobile && navigate(node.uri || '/')}
            >
              <ParseContent
                content={node.previewproject?.previewdescription || ''}
              />
            </div>
          </Content>
        )}
      </AnimatePresence>
    </StyledProjectGridPost>
  )
}

export default Project
