import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet-async'

// Icon
import favicon from 'img/favicon.png'

const removeHTML = (content: string) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

interface SEOProps {
  seo: any
}

const SEO: React.FC<SEOProps> = ({ seo }) => {
  const {
    site,
    wpComponent,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.socialSharingQueryQuery>(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      wpComponent(databaseId: { eq: 204 }) {
        socialSharing {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const defaultImageUrl = wpComponent?.socialSharing?.image
    ? `${site?.siteMetadata?.siteUrl}${wpComponent?.socialSharing?.image?.localFile?.publicURL}`
    : ''

  const image: any = seo.opengraphImage
    ? seo.opengraphImage.sourceUrl
    : defaultImageUrl

  let { canonical } = seo

  if (!canonical) {
    if (seo.opengraphUrl.includes(site?.siteMetadata?.siteUrl)) {
      canonical = site?.siteMetadata?.siteUrl
    } else {
      canonical = `${site?.siteMetadata?.siteUrl}${seo.opengraphUrl}`
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'nl',
      }}
      title={removeHTML(seo.title)}
    >
      {/* Default */}
      {seo.metaDesc && (
        <meta name="description" content={removeHTML(seo.metaDesc)} />
      )}
      {canonical && <link rel="canonical" href={removeHTML(canonical)} />}
      <meta name="image" content={image} />
      <link rel="icon" href={favicon} />

      {/* Language */}
      <meta httpEquiv="content-language" content="nl-nl" />

      {/* OpenGraph */}
      {seo.opengraphTitle && (
        <meta property="og:type" content={seo.opengraphTitle} />
      )}
      {seo.opengraphUrl && (
        <meta property="og:url" content={seo.opengraphUrl} />
      )}
      {seo.title && (
        <meta property="og:title" content={removeHTML(seo.title)} />
      )}
      {seo.metaDesc && (
        <meta property="og:description" content={removeHTML(seo.metaDesc)} />
      )}
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="nl_NL" />

      {/* Smooth Scroll Safari Polyfill */}
      <script
        defer
        src="https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js"
      />
    </Helmet>
  )
}

export default SEO
