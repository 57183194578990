/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

// Images
import Logo from 'img/bbLogoRed.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'
import Bottom from 'components/layout/Header/Bottom'

// Hooks
import useLayout from 'hooks/useLayout'

// Third Party
import styled, { css } from 'styled-components'

const StyledHeader = styled.nav<{ zChange: boolean }>`
  background-color: ${(props) => props.theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 120px;
  padding: 20px 0 10px 0;

  ${({ zChange }) =>
    zChange
      ? css`
          z-index: 10;
        `
      : css`
          z-index: 15;
        `}

  @media screen and (max-width: 991px) {
    padding: 5px 0 10px 0;
    height: 100px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)`
  color: ${({ theme }) => theme.color.grey};
  font-size: 22px;
  line-height: 24px;
  margin-top: auto;
  transition: all 0.3s ease;

  & > svg {
    height: 30px;
    width: 30px;
  }

  .brand-small {
    font-size: 14px;
    line-height: 14px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  &:hover {
    color: ${({ theme }) => theme.color.dark};
  }
`

const BrandNoLink = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: 22px;
  line-height: 24px;
  margin-top: auto;

  & > svg {
    height: 30px;
    width: 30px;
  }

  & svg {
    cursor: pointer;
  }

  .brand-small {
    font-size: 14px;
    line-height: 14px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
  id?: number
}

const Filter = styled.div`
  font-size: 14px;
  display: flex;
  cursor: default;

  @media screen and (max-width: 350px) {
    display: block;
    margin-top: auto;
  }
`

const FilterItem = styled.div<{ active: boolean }>`
  margin-left: 20px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: ${({ theme }) => theme.color.dark};
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
  }

  ${({ active }) =>
    active
      ? css`
          font-weight: ${({ theme }) => theme.font.weight.bold};
        `
      : css`
          font-weight: ${({ theme }) => theme.font.weight.light};
        `}

  @media screen and (max-width: 350px) {
    margin-left: 10px;
    line-height: 16px;
    padding-top: 10px;
  }
`

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
  id,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)
  const layout = useLayout()

  const fields: any = query.wpComponent

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('bb-project-filter')) {
        layout.setFilter(
          JSON.parse(window.localStorage.getItem('bb-project-filter') || '[]')
            .filter
        )
      }
    }
  }, [])

  React.useEffect(() => {
    const json = JSON.stringify({ filter: layout.filter })

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('bb-project-filter', json)
    }
  }, [layout.filter])

  const handleFilter = (event: any, f: string) => {
    event.stopPropagation()

    // @ts-ignore
    if (layout.filter.includes(f)) {
      layout.setFilter(layout.filter.filter((filt: any) => filt !== f))
    } else {
      // @ts-ignore
      layout.setFilter([f])
    }
  }

  const handleFilterAlt = (f: string) => {
    layout.setFilter([f])
    navigate('/')
  }

  return (
    <>
      <StyledHeader zChange={layout.isScrollable}>
        <Container className="container">
          {[2, 9].includes(id || 0) && (
            <Brand to="/">
              <div className="d-flex">
                <Logo />
                <div className="ps-3 d-flex flex-column justify-content-between">
                  <div>
                    <div>BLOKHUIS</div>
                    <div>BRAAKMAN</div>
                  </div>
                  <div className="brand-small">architectenbureau bna</div>
                </div>
              </div>
            </Brand>
          )}

          {[11, 3].includes(id || 0) && (
            <Brand to="/">
              <div className="d-flex">
                <Logo />
              </div>
            </Brand>
          )}

          {[354].includes(id || 0) && (
            <BrandNoLink>
              <div className="d-flex">
                <Logo onClick={() => navigate('/')} />
                <Filter>
                  <FilterItem
                    onClick={(e) => handleFilter(e, 'woningbouw')}
                    active={
                      // @ts-ignore
                      layout.filter && layout.filter.includes('woningbouw')
                    }
                  >
                    WONINGBOUW
                  </FilterItem>
                  <FilterItem
                    onClick={(e) => handleFilter(e, 'utiliteitsbouw')}
                    active={
                      // @ts-ignore
                      layout.filter && layout.filter.includes('utiliteitsbouw')
                    }
                  >
                    UTILITEITSBOUW
                  </FilterItem>
                </Filter>
              </div>
            </BrandNoLink>
          )}

          {[-1].includes(id || 0) && (
            <BrandNoLink>
              <div className="d-flex">
                <Logo onClick={() => navigate('/')} />
                <Filter>
                  <FilterItem
                    onClick={() => handleFilterAlt('woningbouw')}
                    active={layout.currentPageType === 'woningbouw'}
                  >
                    WONINGBOUW
                  </FilterItem>
                  <FilterItem
                    onClick={() => handleFilterAlt('utiliteitsbouw')}
                    active={layout.currentPageType === 'utiliteitsbouw'}
                  >
                    UTILITEITSBOUW
                  </FilterItem>
                </Filter>
              </div>
            </BrandNoLink>
          )}

          <Mobile
            fields={fields}
            setIsScrollable={setIsScrollable}
            isSticky={isSticky}
          />
          <Desktop fields={fields} isSticky={isSticky} />
        </Container>
        <Bottom />
      </StyledHeader>
    </>
  )
}

export default Header
