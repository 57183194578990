import React from 'react'

// Images
import ChevronDown from 'img/chevron-down.inline.svg'

// Third Party
import styled from 'styled-components'

interface ScrollDownButtonProps {}

const SvgWrapper = styled.div`
  cursor: pointer;
`

const ScrollDownButton: React.FC<ScrollDownButtonProps> = () => {
  const handleScrollEvent = () => {
    if (typeof window !== 'undefined') {
      window.scrollBy(0, window.innerHeight)
    }
  }

  return (
    <section>
      <div className="container py-3">
        <div className="d-flex justify-content-center">
          <SvgWrapper onClick={() => handleScrollEvent()}>
            <ChevronDown />
          </SvgWrapper>
        </div>
      </div>
    </section>
  )
}

export default ScrollDownButton
