import React from 'react'

// Third Party
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface WithTitleProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Content = styled(ParseContent)`
  & h1,
  h2,
  h3,
  h4 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    opacity: 0.3;
    font-size: 32px;
    line-height: 1.3;
    word-wrap: break-word;
  }

  @media screen and (max-width: 991px) {
    & h1,
    h2,
    h3,
    h4 {
      padding-bottom: 10px;
    }
  }
`

const WithTitle: React.FC<WithTitleProps> = ({ fields }) => (
  <section className="py-lg-3">
    <div className="container py-xl-5 pt-lg-4 pt-3">
      <div className="row justify-content-center">
        <div className="col-lg-12 ">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <Content content={fields.title || ''} />
            </div>
            <div className="col-lg-8">
              <Content content={fields.description || ''} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default WithTitle
