import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerDefault = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: calc(100vh - 120px);
  max-height: 768.57px;

  @media (max-width: 1199px) {
    max-height: 553.57px;
  }

  @media (max-width: 991px) {
    max-height: 409.57px;
  }

  @media screen and (max-width: 991px) {
    height: calc(100vh - 100px);
  }

  @media (max-width: 576px) {
    max-height: 296.75px;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault>
    <div className="container">
      <BannerWrapper>
        <StyledPlaatjie loading="eager" alt="banner" image={fields?.image} />
      </BannerWrapper>
    </div>
  </StyledBannerDefault>
)

export default BannerDefault
