import React from 'react'
import { navigate } from 'gatsby'

// Elements
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerHome = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: calc(100vh - 120px);
  /* max-height: 768.57px; */
  cursor: pointer;

  @media screen and (max-width: 991px) {
    height: calc(100vh - 100px);
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  width: 100%;
  height: 100%;
  max-height: 768.57px;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 1199px) {
    max-height: 553.57px;
  }

  @media (max-width: 991px) {
    max-height: 409.57px;
  }

  @media (max-width: 576px) {
    max-height: 296.75px;
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => (
  <StyledBannerHome>
    <div className="container pb-lg-5 pb-3">
      <BannerWrapper onClick={() => navigate(fields.project?.uri || '/')}>
        <StyledPlaatjie
          loading="eager"
          alt="banner"
          image={fields?.project?.previewproject?.previewimagebig}
        />
      </BannerWrapper>
    </div>
  </StyledBannerHome>
)

export default BannerHome
