import React from 'react'

// Components
import ScrollDownButton from 'components/flex/Components/ScrollDownButton'
import Gallery from 'components/flex/Components/Gallery'

interface ComponentsShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

interface PostProps {
  [key: string]: any
}

const ComponentsShell: React.FC<ComponentsShellProps> = ({
  fields,
  location = {},
}) => {
  const types: PostProps = {
    scrolldownbutton: ScrollDownButton,
    gallery: Gallery,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default ComponentsShell
