import React from 'react'

// Images
import ChevronDown from 'img/chevron-down.inline.svg'

// Third Party
import styled from 'styled-components'

interface BottomProps {}

const StyledBottom = styled.section`
  position: fixed;
  height: 60px;
  z-index: 5;
  width: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.light};

  @media screen and (max-width: 991px) {
    height: 70px;
    padding-bottom: 10px;
  }
`

const SvgWrapper = styled.div`
  cursor: pointer;
`

export const Bottom: React.FC<BottomProps> = () => {
  const handleScrollEvent = () => {
    if (typeof window !== 'undefined') {
      window.scrollBy({
        top: window.innerHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <StyledBottom className="d-flex justify-content-center align-items-center">
      <SvgWrapper onClick={() => handleScrollEvent()}>
        <ChevronDown />
      </SvgWrapper>
    </StyledBottom>
  )
}

export default Bottom
