/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'

// Images
import chevron from 'img/chevron.svg'

// Third Party
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

interface GalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const imagesLiterals: { [key: string]: string } = {
  s: 'col-4',
  m: 'col-8',
}

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
  width: 100%;
  z-index: 3;
  transition: all 0.3s ease;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    /* height: 250px; */
  }
`

const Background = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  height: calc(100% - 1rem);
  width: calc(100% - 1rem);
  /* background-color: ${({ theme }) => theme.color.dark}; */
  z-index: 2;

  @media screen and (max-width: 575px) {
    top: 4px;
    left: 4px;
    height: calc(100% - 8px);
    width: calc(100% - 8px);
  }
`

const ImageContainer = styled.div`
  position: relative;

  &:hover {
    ${StyledPlaatjie} {
      opacity: 0.5;
    }
  }

  padding-top: 12px;
  padding-bottom: 12px;
`

const CustomRow = styled.div`
  /* margin-left: -4px;
  margin-right: -4px;

  @media screen and (max-width: 575px) {
    margin-left: -8px;
    margin-right: -8px;
  } */
`

const LightboxWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  & .lb {
    &-container {
      background-color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
    }

    &-header {
      background: transparent !important;
      box-shadow: none !important;
      height: 0 !important;
      width: 1320px;
      margin: 0 auto;
      position: relative;

      & .lb-button {
        display: none;
      }

      & .next,
      .prev {
        display: block !important;
        position: absolute;
        top: 50vh;
        opacity: 0.8;
        height: 100px;
        width: 100px;
        background-image: url(${chevron});

        &:hover {
          opacity: 1;
          background-color: transparent !important;
        }
      }

      & .next {
        right: 15px;
        transform: rotate(270deg) !important;
      }

      & .prev {
        left: 15px;
        transform: rotate(90deg) !important;
      }

      @media (max-width: 1399px) {
        & .next {
          right: 80px;
        }

        & .prev {
          left: 80px;
        }
      }

      @media (max-width: 1199px) {
        max-width: 944px;

        & .next {
          right: 15px;
        }

        & .prev {
          left: 15px;
        }
      }

      @media (max-width: 991px) {
        max-width: calc(100% - 30px);

        & .next {
          right: 12%;
        }

        & .prev {
          left: 12%;
        }
      }

      @media (max-width: 600px) {
        & .next,
        & .prev {
          display: none !important;
        }
      }

      @media (max-width: 575px) {
        & .next {
          right: 10px;
        }

        & .prev {
          left: 10px;
        }
      }
    }

    &-canvas {
      align-items: start !important;
      padding-top: 120px;
      padding-bottom: 120px;
      overflow-y: scroll !important;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      img {
        cursor: default;
        min-height: 768.57px;
        width: 1296px;
        max-width: unset;
        max-height: unset;

        @media (max-width: 1399px) {
          width: 1116px;
        }

        @media (max-width: 1199px) {
          min-height: 698px;
          width: 936px;
          margin-left: unset;
        }

        @media (max-width: 991px) {
          min-height: 518px;
          width: 696px;
        }

        @media (max-width: 767px) {
          width: 516px;
        }

        @media (max-width: 575px) {
          min-height: 296.75px;
          width: calc(100vw - 24px);
        }
      }

      @media (max-width: 991px) {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }
  }
`

const Gallery: React.FC<GalleryProps> = ({ fields }) => {
  const [openIndex, setOpenIndex] = React.useState<null | number>(null)
  const images: Array<{
    url: any
    title: string
  }> = []

  fields.images?.forEach((image, index) => {
    images.push({
      url: image?.image?.localFile?.childImageSharp?.original?.src,
      title: `Afbeelding ${index}`,
    })
  })

  return (
    <section>
      <div className="container py-lg-5 py-3">
        <CustomRow className="row">
          {fields.images?.map((image, index) => {
            let size = 's'

            if ((index + 1) % 4 > 1) {
              size = 's'
            } else {
              size = 'm'
            }

            return (
              <ImageContainer
                key={index}
                className={`${imagesLiterals[size]}`}
                onClick={() => setOpenIndex(index)}
              >
                <Background />
                <StyledPlaatjie loading="eager" image={image?.image} alt="" />
              </ImageContainer>
            )
          })}
        </CustomRow>
      </div>{' '}
      <AnimatePresence>
        {openIndex !== null && (
          <LightboxWrapper
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              duration: 0.2,
            }}
          >
            <Lightbox
              images={images}
              startIndex={openIndex}
              onClose={() => {
                setOpenIndex(null)
              }}
              doubleClickZoom={2}
              showTitle={false}
            />
          </LightboxWrapper>
        )}
      </AnimatePresence>
    </section>
  )
}

export default Gallery
